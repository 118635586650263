<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="9" y="3" width="2" height="2" rx="0.5" fill="currentColor"/>
    <rect x="9" y="7" width="2" height="2" rx="0.5" fill="currentColor"/>
    <rect x="9" y="11" width="2" height="2" rx="0.5" fill="currentColor"/>
    <rect x="9" y="15" width="2" height="2" rx="0.5" fill="currentColor"/>
    <rect x="9" y="19" width="2" height="2" rx="0.5" fill="currentColor"/>
    <rect x="13" y="3" width="2" height="2" rx="0.5" fill="currentColor"/>
    <rect x="13" y="7" width="2" height="2" rx="0.5" fill="currentColor"/>
    <rect x="13" y="11" width="2" height="2" rx="0.5" fill="currentColor"/>
    <rect x="13" y="15" width="2" height="2" rx="0.5" fill="currentColor"/>
    <rect x="13" y="19" width="2" height="2" rx="0.5" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
name: "IconMoveLong"
}
</script>
